<template>
    <div class="event-alerts-wrapper" v-show="dialogCount > 0">
        <v-btn @click="toggleDialog" fab x-large dark absolute class="event-alerts-toggle">
            <v-badge color="black">
                <template v-slot:badge>
                    {{ dialogCount }}
                </template>
                <v-icon x-large>mdi-bell</v-icon>
            </v-badge>
        </v-btn>
        <v-dialog transition="slide-x-transition" light v-model="dialog" max-width="720">
            <v-card dark>
                <v-toolbar flat dense dark>
                    <div>Máte {{ dialogCount }} upozornění</div>
                    <v-spacer></v-spacer>
                    <v-btn small rounded light class="mt-1 mr-1" @click="clearDialogs">
                        <v-icon>remove</v-icon>
                        Vzít na vědomí vše
                    </v-btn>
                    <v-btn rounded small light class="mt-1" @click="toggleDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <div class="pb-1">
                    <event-alert v-for="(item) in dialogs" :key="item.key" v-bind:notification="item">
                    </event-alert>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>

import {appBus} from "@/main";
import EventAlert from "./EventAlert";
import {mapActions, mapGetters, mapState} from "vuex";
import {forEach, first, find, isEmpty, includes} from "lodash";
import {ON_EVENT_ALERT_INSERT, ON_EVENT_INSERT} from "@/events";

export default {
    name: 'notificationManager',
    components: {
        EventAlert,
    },
    created() {
        appBus.$on(ON_EVENT_INSERT, (uuid) => {

            // Skip if first loading is in progress
            if (!this.dataLoaded)
                return;

            let event = this.eventByKey(uuid);

            if (event === null)
                return;

            if (event.alert === undefined)
                return;

            if (event.alert === null)
                return;

            if (event.alert.title === undefined)
                return;

            let eventCategory = this.getEventCategoryById(event.eventCategoryId);

            if (!this.canPlayAlertByCategoryAndGroup(eventCategory))
                return;

            if (eventCategory.alert !== 1)
                return;

            if (2 === this.baseId && eventCategory.type === 'call')
                return;

            if (event.deviceId === this.deviceId)
                return;

            if (!this.isEventActive(uuid))
                return;

            if (eventCategory.type === 'call' && event.eventStateId > 1)
                return;

            let soundId;
            if (eventCategory.soundAssetId > 0)
                soundId = parseInt(eventCategory.soundAssetId);

            this.addEventAlert({
                method: 'dialog',
                eventKey: uuid,
                event: null,
                code: 'EVENT_INSERT_' + uuid,
                icon: 'fullscreen',
                title: 'Nová událost',
                content: 'Nová událost',
                state: 0,
                merge: 0,
                priority: 1,
                url: soundId > 0 ? soundId + '.mp3' : null,
            });
        });

        appBus.$on(ON_EVENT_ALERT_INSERT, (event) => {

            let eventCategory = this.getEventCategoryById(event.eventCategoryId);
            let soundId;

            if (eventCategory.soundAssetId > 0)
                soundId = parseInt(eventCategory.soundAssetId);

            this.addEventAlert({
                method: 'dialog',
                eventKey: event.uuid,
                event: event,
                code: 'EVENT_INSERT_' + event.uuid,
                icon: 'fullscreen',
                title: 'Alert události',
                content: 'Alert události',
                state: 0,
                merge: 0,
                priority: 1,
                url: soundId > 0 ? soundId + '.mp3' : null,
            });
        });
    },
    mounted() {
        this.process();
        this.processCurrent();
    },
    data() {
        return {}
    },
    computed: {
        ...mapState({
            current: state => state.notifications.current,
            deviceId: state => state.auth.device.id,
            baseId: state => state.auth.user.baseId,
            centralBaseId: state => state.bases.centralBaseId,
            dataLoaded: state => state.app.dataLoaded,
            updatedAt: state => state.app.updatedAt,
            user: state => state.auth.user,
        }),
        ...mapGetters('auth', {
            userId: 'id',
        }),
        ...mapGetters('events', {
            eventByKey: 'getByKey',
            isEventActive: 'isActive',
        }),
        ...mapGetters('eventCategories', {
            getEventCategoryById: 'getEventCategoryById',
        }),
        ...mapGetters('notifications', {
            dialogs: 'getDialogs',
            queue: 'getQueue',
            isPlaying: 'isPlaying',
            isPaused: 'isPaused',
        }),
        dialog: {
            get() {
                return this.$store.state.notifications.dialog
            },
            set() {
                this.toggleDialog();
            }
        },
        dialogCount() {
            return this.dialogs.length;
        }
    },
    watch: {
        updatedAt() {
            this.process();
        },
        queue() {
            window.console.log("QUEUE UPDATED");
            this.process();
        },
        current() {
            this.processCurrent();
        },
        dialogCount(count, oldCount) {
            if (count > oldCount)
                this.$store.commit('notifications/dialogOpen');

            if (count === 0)
                this.$store.commit('notifications/dialogClose');
        },
    },
    methods: {
        ...mapActions('notifications', {
            setCurrent: 'setCurrent',
            clearDialogs: 'clearDialogs',
        }),
        addEventAlert(payload) {
            this.$store.dispatch('notifications/add', payload);
        },
        process() {
            let currentItem = null;

            if (this.current !== null) {
                currentItem = find(this.queue, ['key', this.current.key]);
                if (currentItem === undefined)
                    this.setCurrent(null);
            }

            forEach(this.queue, (notification) => {

                // if current item is sound notification
                if (notification.url !== undefined || notification?.tts) {
                    if (this.current === null && !isEmpty(this.queue)) {
                        this.setCurrent(first(this.queue).key);
                        return true;
                    }

                    if (this.current !== null && this.current.key === notification.key && this.current.processedAt) {
                        this.$store.dispatch('notifications/finished', notification.key);
                        return true;
                    }
                } else {
                    if (notification.type === undefined)
                        notification.type = 'info';

                    switch (notification.type) {
                        case 'success':
                            this.$noty.success(notification.message);
                            break;
                        case 'error':
                            this.$noty.error(notification.message, {timeout: false});
                            break;
                        case 'warning':
                            this.$noty.warning(notification.message);
                            break;
                        case 'info':
                            this.$noty.info(notification.message);
                            break;
                        default:
                            this.$noty.info(notification.message + ' - ' + notification.type);
                    }
                    this.$store.dispatch('notifications/finished', notification.key);
                }
            });
        },
        processCurrent() {

            if (this.current === null)
                return;

            window.console.log("START CURRENT QUEUE ITEM", this.current);
            this.play(this.current).then(() => {
                window.console.log("END PLAYING SOUND CURRENT QUEUE ITEM", this.current);
            }).finally(() => {
                    this.onComplete()
                }
            );
        },
        onComplete() {
            if (this.current)
                this.$store.dispatch('notifications/finished', this.current.key);
        },
        toggleDialog() {
            this.$store.commit('notifications/dialogToggle');
        },
        play(item) {

            let url;
            if (item.tts)
                url = process.env.VUE_APP_TEREZA_API_URL + '/tts/api?t=' + encodeURI(item.tts.text);

            if (item.url)
                url = process.env.VUE_APP_ASSETS_URL + '/' + item.url;
            
            return new Promise((resolve, reject) => {
                let audio = new Audio();
                audio.autoplay = true;
                audio.onerror = reject;
                audio.onended = resolve;
                audio.src = url;
            }).catch(function (error) {
                window.console.log("ERROR ON PLAYING SOUND CURRENT QUEUE ITEM", process.env.VUE_APP_ASSETS_URL + '/' + item.url, item, error);
            });
        },
        canPlayAlertByCategoryAndGroup(eventCategory) {
            if (includes(['DZIN-EVENT', 'DZIN-EVENT2'], eventCategory.code)) {
                if (this.user?.groups) {
                    includes(this.user.groups, 'DZIN')
                    console.log("GROUP", this.user);
                    return true;
                }
                return false
            }
            return true
        },
    },
    beforeDestroy() {
        appBus.$off('ON_EVENT_INSERT');
    }
}
</script>
