import {uuid} from "vue-uuid";
import {filter, find, remove, forEach, uniq, values} from "lodash";
import api from "@/api/api";

const getDefaultState = () => {
    return {
        queue: [],
        messageSounds: {},
        current: null,
        playing: false,
        paused: false,
        dialog: false,
    }
};

const state = getDefaultState();

const getters = {
    getQueue: (state) => {
        return filter(state.queue, function (item) {
            return item.state === 0;
        });
    },
    getDialogs: (state) => {
        return filter(state.queue, function (item) {
            return item.method === 'dialog' && (item.state === 0 || item.state === 1);
        });
    },
    isPlaying: (state) => {
        return state.playing;
    },
    isPaused: (state) => {
        return state.paused;
    },
};

const actions = {
    getData({dispatch}) {
        api.get('/v2/assets/sounds').then((response) => {
            dispatch('setSounds', response);
        }).catch(function () {

        });
    },
    add({state, commit, rootState}, item) {

        if (!rootState.app.dataLoaded)
            return;

        if (item.method === 'dialog' && find(state, {code: item.code}) !== undefined)
            return;

        item.key = uuid.v4();
        item.state = 0;
        item.createdAt = Date.now();
        item.processedAt = null;

        if (item.method === undefined)
            item.method = 'toast';

        commit('add', item);
    },
    finished({state, commit, dispatch}, key) {

        let item = find(state.queue, ['key', key]);

        if (item === undefined) {
            commit('setCurrent', null);
            return;
        }

        if (item.method === undefined || item.method !== 'dialog') {
            dispatch('remove', key);
        } else {
            if (item.state === 0)
                item.state = 1;
            if (state.current !== null && state.current.key === key) {
                commit('setCurrent', null);
            }
        }
    },
    remove({state, commit}, key) {
        commit('remove', key);
        if (state.current !== null && state.current.key === key) {
            commit('setCurrent', null);
        }
    },
    clearDialogs({state, commit}) {
        let toRemove = [];
        forEach(state.queue, function (item, key) {
            if (item.method === 'dialog') {
                toRemove.push(key);
            }
        });
        forEach(toRemove, function (key) {
            commit('remove', key);
        });
    },
    clearEventDialogs({state, commit}, eventKey) {
        let toRemove = [];
        forEach(state.queue, (item, key) => {
            if (eventKey === item.eventKey) {
                toRemove.push(key);
            }
        });
        forEach(toRemove, function (key) {
            commit('remove', key);
        });
    },
    resetState({commit, dispatch}) {
        commit('resetState');
        dispatch('getData');
    },
    setSounds({commit}, data) {
        forEach(uniq(values(data.assets)), (item) => {
            new Audio(process.env.VUE_APP_ASSETS_URL + '/' + item);
        });
        commit('addMessageSounds', data.types);
    },
    async setCurrent({state, commit, dispatch}, key) {
        if (key === null) {
            state.current = null;
        } else {
            commit('setCurrent', state.queue.find(o => o.key === key));
            dispatch('merge');
        }
    },
    merge({state}) {
        if (state.current.merge === 1) {
            remove(state.queue, (o) => {
                return o.code === state.current.code && o.merge === 1 && o.key !== state.current.key;
            });
        }
    },
    async onNewMessage({state, dispatch, rootState, rootGetters}, item) {
        if (item.userId === rootState.auth.user.id)
            return;

        if (rootState.messages.items[item.id] !== undefined)
            return;

        let event = rootGetters['events/getByKey'](item.eventUuid);

        if (rootGetters['panel/isEventMuted'](event.uuid) === true) {
            return;
        }
        let sound = state.messageSounds[item.type]?.default;
        if (sound) {
            if (state.messageSounds[item.type][item.messageCategoryId] !== undefined)
                sound = state.messageSounds[item.type][item.messageCategoryId];

            if (state.messageSounds[item.type] !== undefined) {
                dispatch('add', {
                    priority: 3,
                    method: 'sound',
                    merge: 1,
                    code: item.type,
                    url: sound,
                });
            }
        }
        if (item.data) {
            const data = JSON.parse(item.data);
            if (data?.tts) {
                dispatch('add', {
                    priority: 2,
                    method: 'sound',
                    merge: 0,
                    code: item.type,
                    tts: data.tts,
                });
            }
        }
    },
    onNewEvent() {
        // if (state.messageSounds[item.type] !== undefined) {
        //     dispatch('add', {
        //         priority: 3,
        //         merge: 1,
        //         code: item.type,
        //         url: state.messageSounds[item.type],
        //     });
        // }
    },
};

const mutations = {

    dialogToggle(state) {
        state.dialog = !state.dialog;
    },
    dialogClose(state) {
        state.dialog = false;
    },
    dialogOpen(state) {
        state.dialog = true;
    },
    add(state, item) {
        state.queue.push(item);
    },
    addMessageSounds(state, data) {
        state.messageSounds = data;
    },
    remove(state, key) {
        state.queue.splice(state.queue.findIndex(item => item.key === key), 1);
    },
    setCurrent(state, item) {
        if (item) {
            item.processedAt = Date.now();
            state.current = item;
        }
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
