<template>
    <v-card class="event-journal" :loading="loading" :disabled="loading">
        <v-toolbar color="default" dark dense>
            <v-toolbar-title>Detail a historie události</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items class="pl-3">
                <v-btn fab x-small color="red" @click="$emit('close')">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mt-2">
            Detail:
            <v-simple-table dense>
                <template v-slot:default>
                    <tbody>
                    <tr>
                        <td>ID:</td>
                        <td>{{ event.id }}</td>
                    </tr>
                    <tr>
                        <td>UUID:</td>
                        <td>{{ event.uuid }}</td>
                    </tr>
                    <tr>
                        <td>Datum založení :</td>
                        <td>{{ event.createdAt }}</td>
                    </tr>
                    <tr>
                        <td>Datum vložení :</td>
                        <td>{{ event.insertedAt }}</td>
                    </tr>
                    <tr>
                        <td>Datum začátku:</td>
                        <td>{{ event.startedAt }}</td>
                    </tr>
                    <tr>
                        <td>Datum ukončení:</td>
                        <td>{{ event.endedAt }}</td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
            Přístup k události:
            <v-simple-table dense>
                <template v-slot:default>
                    <tbody>
                    <tr v-for="(access, key) in eventAccesses(event.uuid)" :key="key" v-bind:event-id="event.id">
                        <td>{{ access.eventRoleName }}</td>
                        <td>{{ access.name }}</td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
            Historie:
            <v-data-table v-if="loading===false"
                :headers="headers"
                :items="result.pretty"
            >
                <template v-slot:[`item.values`]="{ item }">
                    <table v-html="showValues(item.values)"></table>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>

    import api from "@/api/api";
    import forEach from "lodash/forEach";
    import {mapGetters} from "vuex";

    export default {
        name: 'event-view',
        props: {
          event: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                headers: [
                    {text: 'Datum', align: 'left', sortable: true, value: 'date'},
                    {text: 'Uživatel', align: 'left', sortable: true, value: 'user'},
                    {text: 'Zařízení', align: 'left', sortable: true, value: 'device'},
                    {text: 'Typ', align: 'center', sortable: false, value: 'type'},
                    {text: 'Tabulka', align: 'left', sortable: true, value: 'table'},
                    {text: 'Data', align: 'left', sortable: true, value: 'values'},
                ],
                loading: true,
                result: null,
            }
        },
        computed: {
            ...mapGetters('eventAccesses', {
                eventAccesses: 'byEvent',
            }),
        },
        created() {
            this.showEventJournal()
        },
        methods: {
            showEventJournal() {
                api.get('/v2/events/journal/' + this.event.id)
                    .then((result) => {
                        this.result = result;
                        this.loading = false;
                    })
                    .catch(() => {

                    })
                    .finally(() => (this.loading = false))
            },
            showValues(data) {
                let result = '';
                forEach(data, function (value, key) {
                    result += '<tr><td>' + key + '</td><td>'+ value + '</td></tr>';
                });
                return result;
            }
        }
    }
</script>
