<template>
    <div v-if="isActive && !infoOnly">
        <vl-layer-vector :z-index="1001">
            <vl-source-vector :features="trains">
                <vl-style-func :function="trainStyleFactory()">
                </vl-style-func>
            </vl-source-vector>
        </vl-layer-vector>
    </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";

import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Text from 'ol/style/Text';
import Fill from 'ol/style/Fill';
import Point from 'ol/geom/Point';
import Circle from 'ol/style/Circle';
import {transform} from 'ol/proj'

export default {
    name: "train-location",
    data() {
        return {}
    },
    props: {
        showTracks: {
            type: Boolean,
            default: false,
        },
        isRealtime: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapGetters('trainLocation', {
            trains: 'getTrains',
            tracks: 'getTracks',
            isActive: 'isEnabled',
            infoOnly: 'infoOnly',
        }),
    },
    methods: {
        ...mapActions({
            updateTrains: 'trainLocation/updateTrains',
        }),
        trackStyleFactory() {
            return (item) => {
                let color = item.get('traversed') == 1 ? 'darkgray' : 'green';

                let stroke = new Stroke({
                    width: 5,
                    color: color,
                });

                return [
                    new Style({
                        stroke: stroke,
                    }),
                ]
            }
        },
        trainStyleFactory() {
            return (item) => {
                const markerHeight = 100;
                const scale = 0.4;
                let style = [];
                const flatCoordinates = item.get('geometry').flatCoordinates;

                flatCoordinates.forEach((coordinates, index) => {
                    if (index % 4 === 0 && index % 60 === 0) {
                        if (flatCoordinates[index + 2] === undefined) {
                            return
                        }
                        const dx = flatCoordinates[index + 2] - flatCoordinates[index];
                        const dy = flatCoordinates[index + 3] - flatCoordinates[index + 1];
                        const rotation = Math.atan2(dy, dx);
                        style.push(
                            new Style({
                                zIndex: 1,
                                geometry: new Point([flatCoordinates[index], flatCoordinates[index + 1]]),
                                image: new Icon({
                                    src: '/assets/icons/arrow.png',
                                    anchor: [0.75, 0.5],
                                    rotateWithView: true,
                                    rotation: -rotation,
                                }),
                            })
                        )
                    }
                });
                const option = item.get('traversed')
                    ? {width: 3, color: 'red'}
                    : {width: 3, color: 'red', lineDash: [35, 25]};
                style.push(new Style({
                    zIndex: 1,
                    stroke: new Stroke(option),
                }));
                style.push(new Style({
                    zIndex: 1,
                    geometry: new Point(new Point(transform(item.get('begin_track_gps'), this.$store.state.map.defaultProjection, this.$store.state.map.viewProjection))),
                    image: new Circle({
                        fill: new Fill({
                            color: 'red',
                        }),
                        stroke: new Stroke({
                            color: 'black',
                            width: 3,
                        }),
                        radius: 10,
                    }),
                }));
                if (item.get('end_track_gps') !== null) {
                    style.push(new Style({
                        zIndex: 1,
                        geometry: new Point(new Point(transform(item.get('end_track_gps'), this.$store.state.map.defaultProjection, this.$store.state.map.viewProjection))),
                        image: new Circle({
                            fill: new Fill({
                                color: 'white',
                            }),
                            stroke: new Stroke({
                                color: 'black',
                                width: 3,
                            }),
                            radius: 10,
                        }),
                    }));
                }

                style.push(
                    new Style({
                        zIndex: 1,
                        geometry: new Point(transform(item.get('current_gps'), this.$store.state.map.defaultProjection, this.$store.state.map.viewProjection)),
                        image: new Icon({
                            zIndex: 1,
                            src: './assets/icons/map/marker-square.svg',
                            scale: scale,
                            transparent: false,
                            opacity: 1,
                            anchorXUnits: 'fraction',
                            anchorYUnits: 'fraction',
                            anchor: [0.5, 1],
                            color: 'red',
                        }),
                        text: new Text({
                            zIndex: 2,
                            text: '6',
                            font: '60px tereza',
                            offsetY: -1 * ((markerHeight / 100) * 54) * scale,
                            placement: 'point',
                            textBaseline: 'middle',
                            textAlign: 'center',
                            scale: scale,
                            fill: new Fill({
                                color: 'white'
                            })
                        }),
                    }));

                return style;
            }
        },
    },
    mounted() {
        if (this.infoOnly)
            return;

        setInterval(function () {
            if (this.isActive === true) {
                this.updateTrains();
            }
        }.bind(this), 30000);
    }
}
</script>
