<template>
    <vl-layer-vector :zIndex="zIndex" v-if="targetCoordinates && eventCategory.marker.shape">
        <vl-source-vector>
            <vl-feature :properties="{type: featureType, contextMenu: contextMenu, tooltip:targetTooltip(event)}" :id="event.uuid">
                <vl-geom-point :coordinates="targetCoordinates"></vl-geom-point>
            </vl-feature>
            <vl-style-func :function="styleFunc()"/>
        </vl-source-vector>
    </vl-layer-vector>
</template>

<script>
import {mapGetters} from 'vuex'
import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'
import Text from 'ol/style/Text'
import Fill from 'ol/style/Fill'

export default {
    name: 'layer-event-target',
    props: {
        event: Object,
        featureType: {
            type: String,
            default: 'eventTarget',
        },
        contextMenu: {
            type: String,
            default: null,
        },
        zIndex: {
            type: Number,
            default: 102,
        }
    },
    computed: {
        ...mapGetters('events', {
            targetTooltip: 'targetTooltip',
        }),
        eventCategory() {
            return this.$store.getters['eventCategories/getEventCategoryById'](this.event.eventCategoryId)
        },
        targetCoordinates() {
            return this.event && this.event.targetLongitude && this.event.targetLatitude ? [parseFloat(this.event.targetLongitude), parseFloat(this.event.targetLatitude)] : false;
        },
    },
    methods: {
        styleFunc() {

            let eventCategory = this.eventCategory;
            let scale = eventCategory.marker.scale;
            let markerHeight = 100;

            let marker = new Icon({
                src: './assets/icons/map/' + eventCategory.marker.shape,
                crossOrigin: 'anonymous',
                anchorXUnits: 'fraction',
                anchorYUnits: 'fraction',
                anchor: [0.5, 1],
                color: eventCategory.marker.color,
                scale: scale
            });

            let icon = new Text({
                text: eventCategory.marker.iconFont === 'FontAwesome' ? unescape(eventCategory.marker.icon) : eventCategory.marker.icon,
                font: '50px ' + eventCategory.marker.iconFont,
                offsetY: -1 * ((markerHeight / 100) * eventCategory.marker.iconOffset.y) * scale,
                placement: 'point',
                textBaseline: 'middle',
                textAlign: 'center',
                scale: scale,
                fill: new Fill({
                    color: eventCategory.marker.iconColor
                })
            });

            let style = new Style({
                zIndex: 1,
                image: marker,
                text: icon,
            });

            return () => {
                return [
                    style,
                ];
            }
        },
    },
}
</script>
