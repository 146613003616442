<template>
    <div>
        <div class="panel-measure d-flex flex-row map-icons" id="map-toolbar-left">
            <zoom-menu></zoom-menu>
            <v-btn-toggle v-model="type" background-color="grey darken-2" rounded dense dark>
                <v-btn :disabled="disabled" value="LineString" fab small color="grey darken-2">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon size="22" v-on="on">straighten</v-icon>
                        </template>
                        Měření vzdálenosti
                    </v-tooltip>
                </v-btn>
                <v-btn :disabled="disabled" value="Polygon" fab small color="grey darken-2">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon size="28" v-on="on">square_foot</v-icon>
                        </template>
                        Měření plochy
                    </v-tooltip>
                </v-btn>
            </v-btn-toggle>
            <v-btn
                    v-if="isActive"
                    @click="setType(null)"
                    rounded fav
                    class="ml-1"
                    color="grey darken-2"
                    dark
            >
                Vypnout měření
            </v-btn>
            <event-object-menu></event-object-menu>
        </div>

        <vl-layer-vector id="measureAreaLayer" :z-index="1000" :visible="isActive">
            <vl-source-vector ident="measureAreaSource"
                              :features.sync="areaFeatures"
            ></vl-source-vector>
            <vl-style-func :function="styleDrawFunc('area')"></vl-style-func>
        </vl-layer-vector>

        <vl-layer-vector id="measureLayer" :z-index="1000" :visible="isActive">
            <vl-source-vector ident="measureDistSource" :features.sync="distFeatures"
            ></vl-source-vector>
            <vl-style-func :function="styleDrawFunc('dist')"></vl-style-func>
        </vl-layer-vector>

        <vl-interaction-draw
                source="measureDistSource"
                type="LineString"
                :stop-click="true"
                :active="isActiveDist"
                @drawend="distEnd"
        >
            <vl-style-func :function="styleDrawFunc('dist')"></vl-style-func>
        </vl-interaction-draw>

        <vl-interaction-draw
                source="measureAreaSource"
                type="Polygon"
                :stop-click="true"
                :active="isActiveArea"
                @drawend="areaEnd"
        >
            <vl-style-func :function="styleDrawFunc('area')"></vl-style-func>
        </vl-interaction-draw>
    </div>
</template>
<script>

import numeral from 'numeral';
import Fill from 'ol/style/Fill';
import {getLength} from 'ol/sphere';
import {formatLineLength, formatPolygonArea} from "@/helpers/format";

import LineString from 'ol/geom/LineString';
import Polygon from 'ol/geom/Polygon';
import {transform} from 'ol/proj'
import {Circle as CircleStyle, Stroke} from 'ol/style';
import {mapState} from "vuex";
import {unByKey} from 'ol/Observable';
import {createStyle, createPointGeom} from "vuelayers/dist/ol-ext";
import EventObjectMenu from "@/components/geo/Menu";
import ZoomMenu from "@/components/map/Zoom";

numeral.register('locale', 'cs', {
    delimiters: {
        thousands: ' ',
        decimal: ','
    },
})
;
numeral.locale('cs');

export default {
    name: "measure",
    components: {
        EventObjectMenu,
        ZoomMenu,
    },
    data() {
        return {
            disabled: false,
            tooltip: null,
            tooltipPosition: null,
            sketch: null,
            listener: null,
            active: false,
            areaFeatures: [],
            distFeatures: [],
            type: null,
            formatNumber: '0,0.00',
        }
    },
    computed: {
        ...mapState({
            activeEvent: state => state.events.active,
            dataProjection: state => state.map.dataProjection,
            viewProjection: state => state.map.viewProjection,
            defaultProjection: state => state.map.defaultProjection,
            geoStatus: state => state.geo.status,
        }),
        isActive() {
            return (this.isActiveDist || this.isActiveArea);
        },
        isActiveDist() {
            return this.type === 'LineString';
        },
        isActiveArea() {
            return this.type === 'Polygon';
        },
        measureTypeLabel() {
            switch (this.type) {
                case 'LineString':
                    return 'Vzdálenost';
                case 'Polygon':
                    return 'Plocha';
                default:
                    return 'Měření';
            }
        }
    },
    methods: {
        distEnd(event) {
            this.tooltip = null;
            const coords = event.feature.getGeometry().getCoordinates();
            const linestring = new LineString(coords);
            const length = formatLineLength(linestring);
            const feature = {
                type: 'Feature',
                geometry: {
                    type: 'LineString',
                    coordinates: linestring.transform(this.viewProjection, this.dataProjection).getCoordinates(),
                },
                properties: {
                    dist: length,
                    label: length,
                }
            };
            this.distFeatures.push(feature);
            unByKey(this.listener);
        },
        areaEnd(event) {
            this.tooltip = null;
            const coords = event.feature.getGeometry().getCoordinates();
            const polygon = new Polygon(coords);
            const length = getLength(new LineString(polygon.getLinearRing(0).getCoordinates()));
            const area = formatPolygonArea(polygon);
            const position = transform(event.feature.getGeometry().getInteriorPoint().getCoordinates(), this.viewProjection, this.dataProjection);

            const feature = {
                type: 'Feature',
                geometry: {
                    type: 'Polygon',
                    coordinates: polygon.transform(this.viewProjection, this.dataProjection).getCoordinates(),
                },
                properties: {
                    position: [position[0], position[1]],
                    label: area,
                    dist: length,
                    area: area,
                }
            };
            this.areaFeatures.push(feature);
            unByKey(this.listener);
        },
        setType(type) {
            this.type = type;
            if (this.type === null) {
                this.areaFeatures = [];
                this.distFeatures = [];
            }
        },
        styleDrawFunc(type) {

            let strokeColorInner = 'yellow';
            let strokeColorOuter = 'black';
            let labelTextColor = [0, 0, 0, 1];
            let labelEndBackgroundColor = [255, 255, 0, 0.9];
            let labelCenterBackgroundColor = [255, 255, 0, 0.8];
            let polygonFillColor = [255, 255, 0, 0.2];

            return (feature) => {
                const geometry = feature.getGeometry()
                let styles = [];

                styles.push(createStyle({
                    geom: geometry,
                    strokeColor: strokeColorOuter,
                    strokeWidth: 4
                }))

                styles.push(createStyle({
                    strokeColor: strokeColorInner,
                    strokeLineDash: [5],
                    strokeWidth: 2,
                    fillColor: polygonFillColor,
                    image: new CircleStyle({
                        radius: 4,
                        stroke: new Stroke({
                            color: 'black',
                            width: 1,
                        }),
                        fill: new Fill({
                            color: '#ff0',
                        }),
                    })
                }))
                if (geometry instanceof LineString || geometry instanceof Polygon) {

                    let lineString = geometry;
                    if (geometry instanceof Polygon)
                        lineString = new LineString(geometry.getCoordinates()[0]);

                    if (lineString.getCoordinates().length > 2) {
                        lineString.forEachSegment((start, end) => {
                            const line = new LineString([start, end]);
                            const center = line.getCoordinateAt(0.5)

                            styles.push(createStyle({
                                zIndex: 10,
                                geom: createPointGeom(center),
                                strokeColor: 'black',
                                strokeWidth: 10,
                                fillColor: 'red',

                                text: formatLineLength(line),
                                textFont: 'normal 10px Roboto',
                                textFillColor: labelTextColor,
                                textBackgroundFillColor: labelCenterBackgroundColor,
                                textBaseline: 'middle',
                                textAlign: 'center',
                                textPadding: [2, 3, 0, 3],

                            }))
                        });
                    }

                    let label = null;
                    let labelCoordinates = null;
                    let labelOffsetY = 0;

                    if (type === 'dist') {
                        label = formatLineLength(geometry)
                        labelOffsetY = -10;
                        labelCoordinates = geometry.getLastCoordinate();
                    } else {
                        if (geometry instanceof Polygon)
                            labelCoordinates = geometry.getInteriorPoint().getCoordinates();
                        if (lineString.getCoordinates().length > 3)
                            label = formatPolygonArea(geometry) + "\n" + formatLineLength(geometry)
                    }

                    if (labelCoordinates) {
                        styles.push(createStyle({
                            zIndex: 10,
                            geom: createPointGeom(labelCoordinates),
                            strokeColor: 'black',
                            strokeWidth: 10,
                            fillColor: 'red',

                            text: label,
                            textFont: 'normal 12px Roboto',
                            textFillColor: labelTextColor,
                            textBackgroundFillColor: labelEndBackgroundColor,
                            textBaseline: 'middle',
                            textAlign: 'center',
                            textOffsetY: labelOffsetY,
                            textPadding: [3, 5, 1, 5],
                        }))
                    }
                }
                return styles;
            }
        },
    },
    watch: {
        geoStatus(value) {
            this.type = null;
            this.disabled = value !== 0;
        }
    }
}
</script>

<style scoped>

.panel-measure {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    padding: 5px;
}

</style>
