<template>
    <div>
        <vl-layer-vector :zIndex="101">
            <vl-source-vector :features.sync="features" :ident="sourceName">
                <vl-feature
                        :properties="{type: 'eventDestination', tooltip: tooltip(event), active: isEdited, draggable: isEdited}"
                        :id="event.uuid" v-if="coordinates">
                    <vl-geom-point :coordinates="coordinates"></vl-geom-point>
                </vl-feature>
                <vl-style-func :function="styleFunc()"/>
            </vl-source-vector>
        </vl-layer-vector>
        <vl-interaction-translate
                v-if="features !== undefined && features.length > 0 && isEdited"
                :source="sourceName"
                @translatestart="beginTranslate"
                @translateend="endTranslate"
        >
        </vl-interaction-translate>
        <vl-overlay v-if="isEdited" :position="coordinates" style="position: absolute">
            <v-card outlined elevation="3" min-width="300" :disabled="processed" :loading="processed" dark>
                <v-card-subtitle class="pb-0 text--white">Nastavení místa dojezdu techniky</v-card-subtitle>
                <v-card-actions>
                    <v-btn text small color="success" :disabled="processed" @click="save" title="Uložit">
                        <v-icon left small>$vuetify.icons.save</v-icon>
                        Uložit
                    </v-btn>
                    <v-btn v-show="isEventCoordinates" text small color="error" @click="remove" :disabled="processed"
                           title="Odebrat">
                        <v-icon left small>$vuetify.icons.trash</v-icon>
                        Odebrat
                    </v-btn>
                    <v-btn text small @click="end" :disabled="processed" title="Zavřít">
                        <v-icon left small>$vuetify.icons.cancel</v-icon>
                        Zavřít
                    </v-btn>
                </v-card-actions>
            </v-card>
        </vl-overlay>
    </div>
</template>
<script>
    import {mapActions, mapGetters, mapState} from "vuex";
    import Style from 'ol/style/Style'
    import Icon from 'ol/style/Icon'
    import Text from 'ol/style/Text'

    export default {
        name: 'event-destination-map-layer',
        data() {
            return {
                offsetLat: 0,
                offsetLon: 0,
                features: undefined,
            }
        },
        props: {
            event: Object,
        },
        computed: {
            sourceName() {
                return 'destinationPoint-' + this.event.uuid;
            },
            ...mapGetters('eventDestination', {
                isEnabled: 'isEnabled',
                tooltip: 'tooltip',
            }),
            ...mapState({
                processed: state => state.eventDestination.processed,
                editCoordinates: state => state.eventDestination.coordinates,
                eventUuid: state => state.eventDestination.eventUuid,
                activeEventUuid: state => state.events.active,
            }),
            isEdited() {
                return this.isEnabled && this.event.uuid === this.eventUuid;
            },
            isEventCoordinates() {
                return this.event.destinationLongitude && this.event.destinationLatitude;
            },
            coordinates() {
                if (this.isEdited) {
                    return this.editCoordinates;
                } else {
                    return this.isEventCoordinates ? [parseFloat(this.event.destinationLongitude), parseFloat(this.event.destinationLatitude)] : false;
                }
            },
        },
        methods: {
            ...mapActions('eventDestination', {
                save: 'save',
                end: 'end',
                remove: 'remove',
            }),
            beginTranslate(event) {
                this.offsetLon = this.features[0].geometry.coordinates[0] - event.coordinate[0];
                this.offsetLat = this.features[0].geometry.coordinates[1] - event.coordinate[1];
            },
            endTranslate(event) {
                const coordinates = [event.coordinate[0] + this.offsetLon, event.coordinate[1] + this.offsetLat];
                this.$store.dispatch('eventDestination/start', coordinates);
            },
            styleFunc() {
                let scale = 0.5;
                let markerHeight = 100;
                let icon = new Text({
                    text: 'W',
                    font: '50px tereza',
                    offsetY: -1 * ((markerHeight / 100) * 55) * scale,
                    placement: 'point',
                    textBaseline: 'middle',
                    textAlign: 'center',
                    scale: scale,
                });

                return (feature) => {
                    let active = feature.get('active');

                    let marker = new Icon({
                        src: './assets/icons/map/marker-event-destination.svg',
                        crossOrigin: 'anonymous',
                        anchorXUnits: 'fraction',
                        anchorYUnits: 'fraction',
                        anchor: [0.5, 1],
                        scale: scale,
                        color: active ? 'yellow' : 'white',
                    });

                    return [
                        new Style({
                            zIndex: 1,
                            image: marker,
                            text: icon,
                        })
                    ]
                }
            },
        },
      watch: {
        activeEventUuid() {
            this.end();
        },
      }
    }
</script>
