<template>
    <v-layout fill-height column class="event-detail fill-sidebar grey lighten-2">
        <v-flex d-flex class="event-head-wrapper flex-grow-0" v-if="tabIndex !== 'event-map'">
            <event-head v-if="!isCallForm" v-bind:eventKey="activeEvent" @onSelect="showDetail(event)"
                        v-bind:active="true"></event-head>
        </v-flex>
        <div v-if="isCallForm">
            <event-form :isCall="isCall"></event-form>
        </div>
        <v-flex v-if="event.id !== null" d-flex column class="event-detail-content overflow-auto">
            <v-tabs grow :hide-slider="true" background-color="grey lighten-2" class="event-detail-tabs" color="black"
                    v-if="visibleTabs"
                    active-class="grey lighten-2" ref="tabs"
                    v-model="tabIndex"
            >
                <v-tabs-slider color="transparent"></v-tabs-slider>

                <!--MESSAGES TAB-->
                <v-tooltip top open-delay="400" v-for="(item, index) in enabledMessageCategories" :key="index">
                    <template v-slot:activator="{ on }">
                        <v-tab v-on="on" @click="onClick(item.id)" :active-class="item.color"
                               :href="'#event-messages-'+item.id"
                               v-if="isTab('chat') && $can('manage', 'Message')">
                            <v-badge :value="unvisitedByEvent(event, item.id).length > 0"
                                     offset-y="16px"
                                     offset-x="3px"
                                     :color="item.unreadClass"
                                     overlap
                                     :content="unvisitedByEvent(event, item.id).length">
                                <v-icon :size="tabIconSize">ter-chat-{{ item.id }}</v-icon>
                            </v-badge>
                        </v-tab>
                    </template>
                    {{ item.options.tooltip }}
                </v-tooltip>
                <v-tab-item :transition="false" v-for="(item, index) in enabledMessageCategories" :key="index"
                            :value="'event-messages-' + item.id"
                            class="event-messages-tab-content">
                    <event-messages v-if="$can('manage', 'Message')" :eventId="event.id"
                                    :messageCategory="item"></event-messages>
                </v-tab-item>

                <!--MAP TAB-->
                <v-tooltip top open-delay="400" v-if="isMobile">
                    <template v-slot:activator="{ on }">
                        <v-tab href="#event-map" v-on="on">
                            <v-icon size="22">map</v-icon>
                        </v-tab>
                    </template>
                    Mapa
                </v-tooltip>
                <v-tab-item v-if="isMobile" :transition="false" value="event-map" touchless>
                </v-tab-item>

                <!--CONTACTS TAB-->
                <v-tooltip top open-delay="400">
                    <template v-slot:activator="{ on }">
                        <v-tab href="#event-contacts" v-on="on"
                               v-if="isTab('contacts') && $can('view', 'EventContact')">
                            <v-icon size="22">$vuetify.icons.eventContacts</v-icon>
                        </v-tab>
                    </template>
                    Telefonní seznam
                </v-tooltip>
                <v-tab-item :transition="false" value="event-contacts" v-if="$can('view', 'EventContact')">
                    <event-contacts :event-key="event.uuid" :event-id="event.id"></event-contacts>
                </v-tab-item>

                <!--RESOURCES TAB-->
                <v-tooltip top open-delay="400">
                    <template v-slot:activator="{ on }">
                        <v-tab href="#event-resources" v-on="on"
                               v-if="isTab('event-jobs') && $can('view', 'EventResources')">
                            <v-icon size="22">$vuetify.icons.eventResources</v-icon>
                        </v-tab>
                    </template>
                    Technika
                </v-tooltip>
                <v-tab-item :transition="false" value="event-resources"
                            v-if="isTab('event-jobs') && $can('view', 'EventResources')">
                    <sidebar-event-jobs :event="event"></sidebar-event-jobs>
                </v-tab-item>

                <!--INFO TAB-->
                <v-tooltip top open-delay="400" v-if="!isMobile">
                    <template v-slot:activator="{ on }">
                        <v-tab v-if="isTab('form')" href="#event-info" v-on="on">
                            <v-badge class="align-self-center" color="error" offset-y="15" bordered right overlap
                                     v-model="isActiveEventChanged">
                                <template v-slot:badge>
                                    <v-icon size="9">$vuetify.icons.edited</v-icon>
                                </template>
                                <v-icon :size="tabIconSize">$vuetify.icons.eventInfo</v-icon>
                            </v-badge>
                        </v-tab>
                    </template>
                    Další nastavení
                </v-tooltip>

                <v-tab-item :transition="false" value="event-info">
                    <event-detail :allow-chat="false"></event-detail>
                </v-tab-item>

                <!--CHILD EVENTS TAB-->
                <v-tooltip top open-delay="400" v-if="!isMobile">
                    <template v-slot:activator="{ on }">
                        <v-tab href="#event-child" v-if="isTab('related')" v-on="on">
                            <v-icon>$vuetify.icons.eventCalls</v-icon>
                        </v-tab>
                    </template>
                    Historie akcí událostí
                </v-tooltip>
                <v-tab-item :transition="false" value="event-child">
                    <event-child-events ref="eventChild" :event="event"></event-child-events>
                </v-tab-item>
            </v-tabs>
        </v-flex>
        <map-component ref="map" v-if="tabIndex === 'event-map'"
                       @onMapClose="tabIndex='event-contacts'"></map-component>

        <!--        <v-btn fab fixed bottom left light>-->
        <!--            <v-icon large>menu</v-icon>-->
        <!--        </v-btn>-->

        <radio v-if="!isMobile && messageCategory === 2 && $can('manage', 'Message2') && !isLocal(activeEvent)"
               :event-id="event.id"></radio>
        <capture-photo v-if="messageCategory === 2 && $can('manage', 'Message2') && !isLocal(activeEvent)"
                       :event-id="event.id"></capture-photo>
        <MessageForward v-bind:event="event"></MessageForward>
        <confirm-dialog ref="confirm" type="delete"></confirm-dialog>
    </v-layout>
</template>

<script>

import {mapActions, mapGetters, mapState} from 'vuex'
import eventMessages from './Messages'
import eventHead from './Head'
import eventForm from './Form'
import eventDetail from './Detail'
import eventContacts from './Contacts'
import eventChildEvents from './ChildEvents'
import Radio from './MessageRadio'
import {forEach, includes, isEmpty} from "lodash";
import {appBus} from "@/main";
import {ADD_CONTACT_TO_EVENT, SELECT_EVENT_TAB, SHOW_EVENT_VIEW} from "@/events";
import MessageForward from "@/components/panel/sidebar/event/MessageForward";
import SidebarEventJobs from "@/components/panel/sidebar/event/EventJobs";
import CapturePhoto from "@/components/camera/CapturePhoto";
import MapComponent from "@/components/map/Map";

export default {
    name: 'sidebar-event',
    components: {
        CapturePhoto,
        SidebarEventJobs,
        eventMessages,
        eventHead,
        eventDetail,
        eventForm,
        eventContacts,
        eventChildEvents,
        Radio,
        MessageForward,
        MapComponent,
        ConfirmDialog: () => import("@/components/ui/ConfirmDialog"),
    },
    created() {
        appBus.$on(ADD_CONTACT_TO_EVENT, (item) => {
            this.addContact(item);
        });

        appBus.$on(SELECT_EVENT_TAB, (tab) => {
            this.selectTab(tab);
        });
    },
    mounted() {
        if (this.event?.data?.trainNumber) {
            this.$store.dispatch('trainLocation/close');
            this.setTrains({trainNumber: this.event.data.trainNumber, infoOnly: true});
            // setInterval(function () {
            //     this.updateTrains();
            // }.bind(this), 30000);
        }

        if (this.$store.state.events.fromAlert) {
            this.selectTab('event-info');
            this.$store.commit('events/setFromAlert', false);
        }

    },
    data() {
        return {
            readAllDialog: false,
            delay: 200,
            clicks: 0,
            messageCategory: 2,
            tabIconSize: 29,
            tabIndex: null,
        }
    },
    computed: {
        enabledMessageCategories() {
            let items = {};
            forEach(this.eventCategory.messageCategoryIds, (item, index) => {
                if (this.$can('manage', 'Message' + index))
                    items[parseInt(index)] = this.messageCategories[parseInt(index)];
            });
            return items;
        },
        isCallForm() {
            if (this.event.id === null)
                return true;

            if (this.event.id > 0 && includes(['chat', 'support'], this.eventCategory.type))
                return false;

            return includes(['call', 'chat', 'support'], this.eventCategory.type);
        },
        isCall() {
            return this.event.id === null || this.eventCategory.type === 'call' || this.eventCategory.type === 'data';
        },
        unvisited() {
            return this.unvisitedByEvent(this.event, this.messageCategory.id);
        },
        ...mapState({
            messageCategories: state => state.messages.categories,
            isMobile: state => state.isMobile,
        }),
        ...mapGetters('setup', {
            eventTypeOptions: 'eventTypeOptions',
        }),
        ...mapGetters('events', {
            isLocal: 'isLocal',
            canEditEvent: 'canEdit',
            isActiveEvent: 'isActiveEvent',
            event: 'getActiveEvent',
            isActiveEventChanged: 'isActiveEventChanged',
            isActive: 'isActive',
        }),
        ...mapGetters('eventJobs', {
            eventJobs: 'jobs',
        }),
        ...mapGetters('messages', {
            unvisitedByEvent: 'unvisitedByEvent',
        }),
        ...mapGetters('eventTypes', {
            getEventType: 'get',
        }),
        ...mapGetters('eventCategories', {
            getEventCategoryById: 'getEventCategoryById',
        }),
        ...mapState({
            activeEvent: state => state.events.active,
        }),
        eventCategory() {
            return this.getEventCategoryById(this.event.eventCategoryId);
        },
        eventStateId() {
            return this.event.eventStateId;
        },
        isEventStateActive() {
            return this.isActive(this.event.uuid);
        },
        visibleTabs() {
            return !isEmpty(this.eventCategory.tabs);
        }
    },
    methods: {
        ...mapActions('events', {
            cancel: 'cancelActiveEvent',
        }),
        ...mapActions('trainLocation', {
            setTrains: 'setTrains',
            updateTrains: 'updateTrains',
        }),
        ...mapActions('messages',
            {
                readEvent: 'readEvent',
            }
        ),
        onClick(currentCategoryId) {
            console.log(currentCategoryId, this.messageCategory)
            let lastCategoryId = this.messageCategory;
            this.messageCategory = currentCategoryId;
            this.clicks++;
            if (this.clicks === 1) {
                this.timer = setTimeout(() => {
                    this.clicks = 0;
                    this.onSetMessagesAsRead(lastCategoryId, currentCategoryId);
                }, this.delay);
            } else {
                clearTimeout(this.timer);
                this.clicks = 0;
                this.onSetMessagesAsRead(lastCategoryId, currentCategoryId, true);
            }
        },
        isTab(tab) {
            return this.eventCategory.tabs === null || this.eventCategory.tabs[tab] !== undefined;
        },
        async onSetMessagesAsRead(currentCategoryId, lastCategoryId, skipDialog = false) {
            if (lastCategoryId === currentCategoryId) {
                let unvisited = this.unvisitedByEvent(this.event, this.messageCategory);
                if (unvisited.length > 0) {
                    if (skipDialog) {
                        await this.readEvent({key: this.event.uuid, messageCategoryId: currentCategoryId});
                    } else if (
                        await this.$refs.confirm.open(this.enabledMessageCategories[currentCategoryId].name, 'Všechny zprávy v tomto kanále budou označeny za přečtené.')
                    ) {
                        await this.readEvent({key: this.event.uuid, messageCategoryId: currentCategoryId});
                    }
                }
            }
        },
        addContact(item) {
            let payload = {
                eventId: this.event.id,
                contactTypeId: item.contactTypeId,
                name: item.name,
                number: item.number ? item.number.toString() : '',
                custom: 1,
            };
            this.$store.dispatch('eventContacts/save', payload).then(() => {
                // this.loading = false;
            });
        },
        showDetail(event) {
            appBus.$emit(SHOW_EVENT_VIEW, event);
        },
        setMessageCategory(value) {
            this.messageCategory = parseInt(value[1]);
        },
        selectTab(value) {
            this.tabIndex = value;
        }
    },
    watch: {
        tabIndex(val) {
            const regex = /event-messages-(\d+)/gm;
            let match = regex.exec(val);
            if (match) {
                if (!this.isMobile) {
                    const input = document.getElementById('message-form-text-area-' + match[1])
                    if (input) {
                        this.messageCategory = Number(match[1]);
                        setTimeout(() => {
                            input.focus()
                        }, 50)
                    }
                }
            } else {
                this.messageCategory = null;
            }
        },
        isEventStateActive(val) {
            if (val === false) {
                if (this.event.parentId > 0) {
                    let event = this.$store.getters['events/byId'](this.event.parentId);
                    if (event && this.$store.getters['events/isActive'](event.uuid)) {
                        this.$store.dispatch('events/setActiveEvent', event.uuid);
                    } else {
                        this.$store.dispatch('events/setActiveEvent', false);
                    }

                } else {
                    this.$store.dispatch('events/setActiveEvent', false);
                }
            }
        }
    },
    beforeDestroy() {
        this.$store.dispatch('trainLocation/close');
        appBus.$off(ADD_CONTACT_TO_EVENT);
    }
}
</script>
