import {pickBy, orderBy} from "lodash";
import {stateMerge} from "../../object-merge";
import forEach from "lodash/forEach"

const getDefaultState = () => {
    return {
        local: {},
    }
};
const state = getDefaultState();

const getters = {
    byEvent: (state) => {
        return (key) => {
            return orderBy(pickBy(state.local, function (item) {
                return item != null && item.eventUuid === key;
            }), ['sort'], ['asc']);
        }
    },
    eventAccessOptions: (state, getter, rootState) => {
        let options = [];
        options.push({ header: 'Skupiny' });
        forEach(rootState.groups.items, (item) => {
            options.push({
                id: item.id,
                uid: 'g' + item.id,
                type: 'group',
                code: item.code,
                title: item.name,
            });
        });
        return options;
    },
};

const actions = {
    async set({commit}, payload) {
        commit("MERGE_STATE", payload)
    },
    resetState({commit}) {
        commit('resetState');
    },
};

const mutations = {
    MERGE_STATE(state, data) {
        stateMerge(state, data, 'local', false, true)
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
