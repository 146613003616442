<template>
    <div>
        <vl-layer-vector :zIndex="20">
            <vl-source-vector ident="bases" :features="bases">
                <vl-style-func :function="styleFunc()"/>
            </vl-source-vector>
        </vl-layer-vector>
    </div>
</template>

<script>

import {mapGetters} from 'vuex'
import Style from 'ol/style/Style'
import Fill from 'ol/style/Fill'
import Text from 'ol/style/Text'
import Icon from 'ol/style/Icon'
import {forEach} from "lodash";

export default {
    name: 'layer-bases',
    computed: {
        bases() {
            let items = [];
            forEach(this.defaultBases, (item) => {
                if (item.latitude && item.longitude) {
                    items.push({
                        type: 'Feature',
                        id: parseInt(item.id),
                        geometry: {
                            type: 'Point',
                            coordinates: [parseFloat(item.longitude), parseFloat(item.latitude)],
                        },
                        properties: {
                            code: item.code,
                            type: 'base',
                            tooltip: this.tooltip(item),
                        },
                    });
                }
            });
            return items;
        },
        ...mapGetters('bases', {
            defaultBases: 'defaultBases',
            tooltip: 'tooltip',
        }),
    },
    methods: {
        styleFunc() {

            let scale = 0.50;
            let markerHeight = 100;

            let marker = new Icon({
                color: '#ffffff',
                src: './assets/icons/map/marker-base.svg',
                anchor: [0.5, 1],
                scale: scale,
                crossOrigin: 'anonymous',
            });

            let text = new Text({
                text: '',
                offsetY: -1 * ((markerHeight / 100) * 25) * scale,
                scale: scale * 2.6,
                fill: new Fill({
                    color: 'black'
                }),
            });

            return (feature) => {

                let properties = feature.getProperties();
                text.setText(properties.code);

                return [
                    new Style({
                        text: text,
                        image: marker,
                    })
                ];
            }
        },
    },
}
</script>
